import { Mission, useInvests, useMissions } from "../../hooks/useSubgraph";
import { Suspense, useState } from "react";
import { useActiveWeb3React, useLatestBlockNumberV2 } from "../../hooks/useActiveWeb3React";
import Lottie from 'lottie-react';
import loadingAnimation from "../../assets/lotties/loading_alien.json";
import ErrorBoundary from "../../ErrorBoundary";
import MissionTeaserCard from "./components/MissionTeaserCard";
import { useMissionCollection } from "../../hooks/useFirestore";
import cx from "classnames";

export default function MissionPage() {
  const [currFilter, setCurrFilter] = useState(MissionFilter.All);

  return (
    <div className="mx-auto max-w-screen-2xl w-4/5">
      <Suspense fallback={
        <div className="mx-auto w-40">
          <Lottie animationData={loadingAnimation} height={200} width={200} />
        </div>
      }>

        <MissionGridHeader currFilter={currFilter} onFilterChanged={(filter) => setCurrFilter(filter)} />
        <MissionGrid currFilter={currFilter} />
      </Suspense>
    </div >
  );
}

export function MissionGridHeader(props: { currFilter: MissionFilter, onFilterChanged: (filter: MissionFilter) => void }) {
  function addHighlight(filter: MissionFilter) {
    return props.currFilter.toString() === filter.toString() ? "bg-spex-500" : "bg-slate-800";
  }

  return (
    <div className="flex flex-row border bg-gray-900 border-none rounded-2xl p-8 shadow-lg">
      <div className="space-y-4">
        <h1 className="text-4xl text-gray-200">Cosmic Missions</h1>
        <div className="space-y-1">
          <h2 className="text-gray-400">Explore & conquer using your <span className="text-spex-400 font-bold">SPEX</span>.</h2>
          <h2 className="text-gray-400">Claim your rewards.</h2>
        </div>
      </div>

      <div className="flex flex-row divide-x divide-solid divide-gray-900 ml-10 items-end">
        <button className={cx("px-2 h-9 uppercase font-semibold rounded-l-lg hover:bg-spex-700 focus:bg-spex-500 active:bg-spex-500", addHighlight(MissionFilter.New))}
          onClick={() => props.onFilterChanged(MissionFilter.New)}>New</button>
        <button className={cx("px-2 h-9 uppercase font-semibold hover:bg-spex-700 focus:bg-spex-500 active:bg-spex-500", addHighlight(MissionFilter.Claim))}
          onClick={() => props.onFilterChanged(MissionFilter.Claim)}>Claim</button>
        <button className={cx("px-2 h-9 uppercase font-semibold rounded-r-lg hover:bg-spex-700 focus:bg-spex-500 active:bg-spex-500", addHighlight(MissionFilter.All))}
          onClick={() => props.onFilterChanged(MissionFilter.All)}>All</button>
      </div>
    </div>
  )
}

function MissionGrid(props: { currFilter: MissionFilter }) {
  const missions = useMissions();
  const [value] = useMissionCollection()
  const { account } = useActiveWeb3React()
  const invests = useInvests().filter((invest) => {
    return invest.address.localeCompare(account || "", undefined, { sensitivity: 'accent' }) === 0
  });
  const currentBlockNumber = useLatestBlockNumberV2();
  const filter = (m: Mission) => {
    switch (props.currFilter) {
      case MissionFilter.New:
        return m.start.gt(currentBlockNumber)
      case MissionFilter.Claim:
        // If the user has invested somewhere, include it otherwise show everything ended
        const hasInvest = invests.find((invest) => invest.mission.missionId === m.missionId);
        return hasInvest || (invests.length < 1 && m.end.lt(currentBlockNumber))
      default:
        return true
    }
  }

  const missionItems = missions
    .filter(filter)
    .sort((m1, m2) => m1.start.lt(m2.start) ? 1 : -1) // show the most recent first
    .map(mission => {
      const missionEntry = value?.docs.find((doc) => doc.id === mission.missionId)?.data()
      return { mission, moodImageUrl: missionEntry?.bgImgUrl }
    })
    .map((missionWithUrl) => {
      const { mission, moodImageUrl } = missionWithUrl
      return (
        <ErrorBoundary key={mission.id.toHexString()}>
          <Suspense fallback={<div>Loading Mission {mission.id.toHexString()}...</div>}>
            <MissionTeaserCard mission={mission} chainId={5} moodImageUrl={moodImageUrl} />
          </Suspense>
        </ErrorBoundary>
      )
    }
    )

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto my-8 max-w-3xl w-full place-center">
      {missionItems}
    </div>
  );
}

enum MissionFilter {
  All,
  New,
  Claim
}