import deployment from "./deployment.json"
import ERC20ABI from "../contracts/ERC20.abi.json";
import useSWR from "swr";
import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { useActiveWeb3React } from "../hooks/useActiveWeb3React";

export default function useCosmicContracts() {
  const { chainId } = useActiveWeb3React();

  switch (chainId) {
    case 5:
      return deployment["5"].goerli.contracts;
    case 31337:
      return deployment["31337"].localhost.contracts;
    default:
      return deployment["31337"].localhost.contracts; // TODO throw
  }
}

export function useSupportedChainIDs() {
  return useMemo(() => [5, 31337], []);
}

type AnyContract = {
  address: string;
  abi: any;
};

export function useContract(c: AnyContract) {
  const { library } = useActiveWeb3React();

  const signer = library.getSigner();

  return useMemo(() => new Contract(c.address, c.abi, signer), [c, signer]);
}

export function useERC20(address: string) {
  return useContract({ address, abi: ERC20ABI });
}

export function useContractView(
  c: AnyContract,
  method: string,
  args: Array<unknown>
) {
  const { library } = useActiveWeb3React();

  const { data } = useSWR(
    library ? [method, args] : ["", args],
    (method, args) => {
      if (method === "") {
        return undefined;
      }
      const contract = new Contract(c.address, c.abi, library.getSigner());

      return contract[method](...args);
    },
    { suspense: true }
  );

  return data;
}
