import useSWR from "swr";
import { fetchEtherBlockCountdown, resolveEtherscanBaseUrl } from "../services/etherscan";
import { useChainId } from "./useActiveWeb3React";

export function useEtherBlockCountdown(blockno: string, chainId?: number) {
    const activeChainId = useChainId()
    const chain = chainId || activeChainId
    const { data, error } = useSWR([blockno, chain], () => {
        return fetchEtherBlockCountdown(blockno, resolveEtherscanBaseUrl(chain));
    });
    return {
        countDown: data,
        isLoading: !error && !data,
        isError: error
    }
}

export function useEtherBlockCountdownV2(blockno: string, chainId?: number): number {
    const activeChainId = useChainId()
    const chain = chainId || activeChainId
    const { data } = useSWR(
        [blockno, chain],
        async () => {
            return fetchEtherBlockCountdown(blockno, resolveEtherscanBaseUrl(chain));
        }, { suspense: true });
    return data || 0; //TODO what the fuck
}