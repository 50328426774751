import { useCountDown } from "../../hooks/useCountdown";

export function formatDuration(duration: number) {
    const hours = Math.floor(duration / 1000 / 60 / 60);
    const minutes = Math.floor(duration / 1000 / 60) % 60;
    const seconds = Math.floor(duration / 1000) % 60;

    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return hours + ":" + formattedMinutes + ":" + formattedSeconds;
}

export default function CountDownComponent(props: { target: number }) {
    const msLeft = Math.max(0, useCountDown(props.target));

    return (
        <span>{formatDuration(msLeft)} </span>
    )
}

