import { useCollectionDataOnce, useCollectionOnce } from 'react-firebase-hooks/firestore';

import { initializeApp } from "firebase/app";
import { collection, getFirestore, query, where } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDcUhPZB9ofLJzafWkzpraqjDFgve7K6os",
    authDomain: "spex-functions.firebaseapp.com",
    projectId: "spex-functions",
    storageBucket: "spex-functions.appspot.com",
    messagingSenderId: "827305028389",
    appId: "1:827305028389:web:012a749e20d4d6db5548cc",
    // measurementId: "G-71GZ6N2V2K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export function useMissionCollection() {
    return useCollectionOnce(
        collection(db, 'missions')
    )
}

