import { useBlockTimestamp, useLatestBlockNumberV2 } from "../../../hooks/useActiveWeb3React";
import { Mission, useInvests } from "../../../hooks/useSubgraph";
import Card from "../../../components/Card";
import ErrorBoundary from "../../../ErrorBoundary";
import { PropsWithChildren, Suspense } from "react";
import { prettyEther } from "../../../utils";
import { useAPR } from "../missions";
import cx from "classnames";

export default function MissionBriefingStats(props: { mission: Mission; }) {
    const spacer = <div className="h-3"></div>;
    const mission = props.mission;

    return (
        <Card>
            <div className="flex flex-col w-full">
                <div className="font-semibold text-3xl text-gray-600 mb-5">MISSION BRIEFING</div>
                <MissionBriefingRow title="Identifier" value={"0x" + mission.id.toString()}></MissionBriefingRow>
                <MissionBriefingRow title="Mission Status">
                    <MissionStatusField mission={mission} />
                </MissionBriefingRow>
                {spacer}
                <MissionBriefingRow title="Launch">
                    <MissionLaunchField mission={mission} />
                </MissionBriefingRow>
                <MissionBriefingRow title="Return">
                    <MissionReturnField mission={mission} />
                </MissionBriefingRow>
                <MissionBriefingRow title="Duration">
                    <MissionDurationField mission={mission} />
                </MissionBriefingRow>
                <div className="h-[1px] my-5 w-4/5 bg-gradient-to-r from-gray-400 via-spex-400 to-purple-500 self-center"></div>
                <MissionBriefingRow title="Curr. Deposits">
                    <MissionSpexField value={prettyEther(mission.pendingStake, 1)} />
                </MissionBriefingRow>
                <MissionBriefingRow title="Num. Of Investors">
                    <MissionInvestorField mission={mission} />
                </MissionBriefingRow>
                {spacer}
                <MissionBriefingRow title="Total Rewards">
                    <MissionSpexField value={prettyEther(mission.totalReward, 0)} />
                </MissionBriefingRow>
                <MissionBriefingRow title="Guild Tax" value={mission.tax.toString() + "%"}></MissionBriefingRow>
                <MissionBriefingRow title="Est. APR">
                    <MissionAprField mission={mission} />
                </MissionBriefingRow>

            </div>
        </Card>
    );
}

function MissionBriefingRow(props: PropsWithChildren<{ title: string; value?: string; }>) {
    return (
        <div className="flex justify-between items-center">
            <div className="text-gray-300">{props.title}</div>
            <MissionBriefingSuspendedField>
                {props.value ? <MissionBriefingField value={props.value} /> : props.children}
            </MissionBriefingSuspendedField>
        </div>
    );
}

function MissionBriefingField(props: { className?: string, value: string; }) {
    return (
        <div className={cx("text-gray-400", props.className)}>{props.value}</div>
    );
}

function MissionBriefingSuspendedField(props: PropsWithChildren<any>) {
    return (
        <div>
            <ErrorBoundary render={() => <MissionBriefingField className="text-red-400" value="NaN" />}>
                <Suspense fallback={<MissionFieldLoading />}>
                    {props.children}
                </Suspense>
            </ErrorBoundary>
        </div>
    );
}

function MissionFieldLoading() {
    return (
        <div className="animate-pulse bg-slate-700 rounded w-28 h-3" />
    )
}

function MissionStatusField(props: { mission: Mission; }) {
    const status = useMissionStatus(props.mission);
    return (
        <MissionBriefingField className={status.color} value={status.status} />
    );
}

function MissionLaunchField(props: { mission: Mission; }) {
    const startDate = useBlockTimestamp(props.mission.start.toNumber());
    const startLabel = startDate.toLocaleDateString() + ", " + startDate.toLocaleTimeString();
    return (
        <MissionBriefingField value={startLabel} />
    );
}
function MissionDurationField(props: { mission: Mission; }) {
    const startDate = useBlockTimestamp(props.mission.start.toNumber());
    const returnDate = useBlockTimestamp(props.mission.end.toNumber());
    const duration = returnDate.valueOf() - startDate.valueOf();

    const hours = Math.floor(duration / 1000 / 60 / 60);
    const minutes = Math.floor(duration / 1000 / 60) % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return (
        <MissionBriefingField value={hours + " Hrs " + formattedMinutes + " Min"} />
    );
}
function MissionReturnField(props: { mission: Mission; }) {
    const returnDate = useBlockTimestamp(props.mission.end.toNumber());
    const returnLabel = returnDate.toLocaleDateString() + ", " + returnDate.toLocaleTimeString();
    return (
        <MissionBriefingField value={returnLabel} />
    );
}

function MissionInvestorField(props: { mission: Mission; }) {
    const investments = useInvests().filter(invest => invest.mission.missionId === props.mission.missionId);
    return (
        <MissionBriefingField value={investments.length.toString()} />
    );
}

function MissionSpexField(props: { value: string }) {
    return (
        <div className="flex flex-row gap-1">
            <MissionBriefingField value={props.value} />
            <MissionBriefingField className="text-spex-400 font-semibold" value={"SPEX"} />
        </div>

    )
}
function MissionAprField(props: { mission: Mission; }) {
    const apr = (useAPR(props.mission) * 100);
    const aprLabel = apr >= Infinity ? "∞" : apr.toFixed(2) + " %";
    return (
        <MissionBriefingField className="text-green-400" value={aprLabel} />
    );
}

function useMissionStatus(mission: Mission) {
    const currentBlockNumber = useLatestBlockNumberV2();
    const missionLaunched = mission.start.lt(currentBlockNumber) && mission.end.gt(currentBlockNumber);
    const fueling = mission.start.gt(currentBlockNumber);
    const claimable = mission.end.lt(currentBlockNumber);

    if (missionLaunched) {
        return {
            status: "Active",
            color: "text-red-400"
        };
    }
    if (fueling) {
        return {
            status: "Fueling",
            color: "text-green-400"
        }
    }
    if (claimable) {
        return {
            status: "Claimable",
            color: "text-blue-400"
        }
    }
    throw Error("Unknown mission status!");
}
