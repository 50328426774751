import { useEffect, useState } from 'react';

// Target has to come from a date. Just 10 for a ten second countdown will not work
export function useCountDown(target: number) {
    const [timeLeft, setTimeLeft] = useState(target - Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(target - Date.now());
        }, 1000);

        return () => clearInterval(interval);
    }, [target]);

    return timeLeft;
}