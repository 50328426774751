import {
  useMarket,
  useNumberOfMarkets,
} from "./market";
import ErrorBoundary from "../../ErrorBoundary";
import { BigNumber } from "@ethersproject/bignumber";
import { Suspense, useEffect, useState } from "react";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import MarketCard from "./components/MarketCard";

export default function MarketPage() {
  const { account } = useActiveWeb3React();

  return (
    <div className="mx-auto my-8 max-w-3xl">
      <Suspense fallback={<span>Loading Markets...</span>}>
        {account && <Markets />}
        {!account && <ConnectWallet />}
      </Suspense>
    </div>
  );
}

function ConnectWallet() {
  return (
    <div className="flex flex-row border bg-gray-900 border-none rounded-2xl p-8 shadow-lg">
      <div className="space-y-4">
        <h1 className="text-4xl text-gray-200">Something is missing...</h1>
        <p>
          Connect your wallet to view the markets.
        </p>
      </div>
    </div>
  )
}

function Markets() {
  const numMarkets = useNumberOfMarkets();

  return (
    <div className="flex flex-col space-y-8">
      {Array(numMarkets)
        .fill(0)
        .map((_, i) => (
          <ErrorBoundary key={i}>
            <Suspense fallback={<div>Loading Mission {i}...</div>}>
              <MarketComponent index={i} />
            </Suspense>
          </ErrorBoundary>
        ))
        .reverse()
      }
    </div >
  );
}

function MarketComponent(props: { index: number }) {
  const market = useMarket(props.index)
  const { account, library } = useActiveWeb3React()
  const [balance, setBalance] = useState(BigNumber.from(0));

  useEffect(() => {
    async function getBalance() {
      const result = await library.getBalance(account)
      setBalance(result)
    }
    getBalance()
  }, [account])

  return (
    <>
      <MarketCard
        key={props.index.toString()}
        marketId={props.index}
        market={market}
        balance={balance}
        quoteSymbol={"SPEX"}
        baseSymbol={"ETH"}
      />
    </>
  )
}

