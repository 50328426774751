import { formatEther, parseEther } from "@ethersproject/units";
import { SyntheticEvent, useState } from "react";
import Button from "../../../components/Button";
import { calculateMarketPayout, Market, usePerformBuy } from "../market";
import { BigNumber } from "@ethersproject/bignumber";
import TokenInput from "../../../components/TokenInput";
import PercentageOptions from "../../../components/PercentageOptions";

type MarketProps = {
    marketId: number,
    market: Market,
    balance: BigNumber,
    baseSymbol: string
    quoteSymbol: string
}

// TODO balance does not get refreshed after a buy
// TODO extract common components from staking and claim panels
// Protect against overflow/underflow/invalid values in inputs vs ether and so on.
// Fix BigNumber <-> Number game
export default function MarketCard(props: MarketProps) {
    function inputAsEther() { return parseEther(input || "0"); }
    function payoutAsString(value: BigNumber) { return formatEther(calculateMarketPayout(value, props.market)); }

    const [input, setInput] = useState("0");
    const [pending, setPending] = useState(false);

    const balance = Number(formatEther(props.balance));

    const capacity = Number(formatEther(props.market.capacity))
    const receive = Number(payoutAsString(inputAsEther()));
    const conversion = payoutAsString(parseEther("1"));
    const roundedCap = Number(capacity.toFixed(5))

    const exceedsBalance = Number(input) > balance;
    const exceedsCapacity = receive > capacity;

    const enabled = props.market.active && !pending && !exceedsBalance && !exceedsCapacity;
    let bttnText = pending ? "Waiting for confirmation..." : "Swap";

    const swap = usePerformBuy(props.marketId)
    const onSwap = async (e: SyntheticEvent) => {
        try {
            setPending(true);
            await swap(inputAsEther())
        } finally {
            setPending(false)
            setInput("0");
        }
    }

    return (
        <div className="flex flex-col rounded-2xl bg-gray-900 p-8 items-center shadow-3xl">
            <div className="flex flex-row w-full">
                <h1 className="text-2xl font-bold grow self-bottom text-gray-200">Market #{props.marketId}</h1>
                <div className="self-end-top rounded border-none bg-spex-500 p-1">{props.market.active ? "OPEN" : "CLOSED"}</div>
            </div>
            <div className="flex flex-row items-center space-x-2 mt-4">
                <span className={roundedCap > 0 ? "text-gray-400" : "text-red-600"}>ONLY</span>
                <span className="font-bold text-3xl">{roundedCap} <span className="text-spex-500">SPEX</span></span>
                <span className={roundedCap > 0 ? "text-gray-400" : "text-red-600"}>LEFT</span>
            </div>

            <div className="flex flex-row my-8 space-x-4 border rounded p-4 border-gray-600">
                <div className="flex flex-col">
                    <TokenInput
                        header="You Pay"
                        footer={"Max. " + Number(formatEther(props.balance)).toFixed(6) + " " + props.baseSymbol}
                        value={input}
                        symbol={props.baseSymbol}
                        onUserInput={(input) => { setInput(input); }}
                        error={exceedsCapacity
                            ? "Amount exceeds max. market capacity!"
                            : exceedsBalance ? "Amount exceeds your balance!" : undefined}
                    />
                    <div className="mt-3">
                        <PercentageOptions total={balance} onOptionSelected={(amount) => setInput(amount.toString())} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <TokenInput
                        header="You Receive"
                        footer={"1 " + props.baseSymbol + " = " + conversion + " " + props.quoteSymbol}
                        disabled={true}
                        value={receive}
                        onUserInput={() => { }}
                        symbol={props.quoteSymbol}
                    />
                    <Button onClick={onSwap} className="mt-3 w-full" disabled={!enabled || pending}>{bttnText}</Button>
                </div>
            </div>
        </div>
    )
}