import { InjectedConnector } from "@web3-react/injected-connector";
import Button from "./components/Button";
import useCosmicContracts, { useSupportedChainIDs } from "./contracts/useCosmicContracts";
import { useTokenBalances } from "./hooks/useTokenBalances";
import { useActiveWeb3React } from "./hooks/useActiveWeb3React";
import { Suspense, useEffect, useMemo } from "react";
import { prettyEther } from "./utils";

export function useInjectedConnector() {
  const supportedChainIds = useSupportedChainIDs();

  return useMemo(() => {
    return new InjectedConnector({
      supportedChainIds,
    });
  }, [supportedChainIds]);
}

export default function ConnectButton() {
  const { account, activate, deactivate, error } = useActiveWeb3React();

  const injected = useInjectedConnector();

  const startConnect = async () => {
    await activate(injected);
  };

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        startConnect();
      }
    })
  }, [activate])

  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (account) {
    return (
      <div className="align-center flex items-center space-x-2">
        <div className="flex flex-col items-end text-sm">
          <Suspense fallback={""}>
            <div className="mr-3">
              <BalanceDisplay />
            </div>
          </Suspense>
        </div>
        <Button onClick={deactivate}>
          {`${account.slice(0, 6)}...${account.slice(
            account.length - 4,
            account.length
          )}`}
        </Button>
      </div>
    );
  }

  return <Button onClick={startConnect}>Connect to a wallet</Button>;
}

function BalanceDisplay() {
  const { SPEXToken } = useCosmicContracts();

  const spexBalance = useTokenBalances(SPEXToken.address);
  return (
    <div className="flex flex-col items-end backdrop-blur-[2px] p-1 border rounded border-none">
      <span className="text-gray-400 text-xs">Available Balance</span>
      <span className="font-bold text-lg">{prettyEther(spexBalance)} <span className="text-spex-400">SPEX</span></span>
    </div>
  )
}
