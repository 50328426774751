import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const cls = (input: any) =>
    input
        .replace(/\s+/gm, " ")
        .split(" ")
        .filter((cond: any) => typeof cond === "string")
        .join(" ")
        .trim();

export const prettyEther = (num: BigNumber | string, decimals = 3) => {
    return Number(formatUnits(num, 18)).toFixed(decimals)
}