import React from 'react'
import { escapeRegExp } from '../utils'

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export default function TextInput({
    value,
    onUserInput,
    placeholder,
    ...rest
}: {
    value: string | number
    onUserInput: (input: string) => void
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
    const enforcer = (nextUserInput: string) => {
        if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
            onUserInput(nextUserInput)
        }
    }
    return (
        <input
            {...rest}
            value={value}
            onChange={event => {
                // replace commas with periods, because we exclusively uses period as the decimal separator
                enforcer(event.target.value.replace(/,/g, '.')) // TODO copy & paste, do we really need?
            }}
            // universal input options
            inputMode="decimal"
            title={'Token Amount'}
            autoComplete="off"
            autoCorrect="off"
            // text-specific options
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder={placeholder || '0.0'}
            minLength={1}
            maxLength={79}
            spellCheck="false"
        />
    )
}