import { formatEther, parseEther } from "@ethersproject/units";
import { useState } from "react";
import useCosmicContracts from "../../../contracts/useCosmicContracts";
import { useTokenBalances } from "../../../hooks/useTokenBalances";
import { useJoinMission } from "../missions";
import { Mission } from "../../../hooks/useSubgraph";
import Button from "../../../components/Button";
import TokenInput from "../../../components/TokenInput";
import PercentageOptions from "../../../components/PercentageOptions";

export function StakingPanel(props: { mission: Mission; }) {
    const [input, setInput] = useState("0");
    const [pendingStake, setPendingStake] = useState(false)
    const { SPEXToken } = useCosmicContracts();
    const spexBalance = useTokenBalances(SPEXToken.address);
    const stakeMission = useJoinMission(props.mission.id);

    const onStake = async (stake: number) => {
        try {
            setPendingStake(true);
            await stakeMission(parseEther(stake.toString()))
        } finally {
            setPendingStake(false)
            setInput("0");
        }
    }

    const balance = Number(formatEther(spexBalance));
    const enabled = balance && Number(input) > 0 && Number(input) <= balance;
    const bttnText = pendingStake ? "Waiting for confirmation" : "Stake"

    return (
        <div className="flex flex-col w-full">
            <TokenInput
                value={input}
                onUserInput={(input) => { setInput(input); }}
                symbol="SPEX"
                header="Your Investment"
                footer={"Max. " + balance + " SPEX"}
            />

            <div className="mt-3">
                <PercentageOptions total={balance} onOptionSelected={(amount) => setInput(amount.toString())} />
            </div>
            <div className="grow" />
            <Button onClick={() => { onStake(Number(input)) }} className="mt-3" disabled={!enabled || pendingStake}>{bttnText}</Button>
        </div>
    )
}
