import { Suspense } from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import { useEtherBlockCountdownV2 } from "../../../hooks/useEtherscan";
import { useCountDown } from "../../../hooks/useCountdown";
import { BigNumber } from "@ethersproject/bignumber";

export type Props = { blockNumber: BigNumber, chainId: number; }

export default function MissionCountDown(props: Props) {
    return (
        <div>
            <ErrorBoundary>
                <Suspense fallback={<MissionCountDownLoading />}>
                    <MissionCountDownInternal blockNumber={props.blockNumber} chainId={props.chainId} />
                </Suspense>
            </ErrorBoundary>
        </div>
    );
}
function MissionCountDownInternal(props: Props) {
    const countdown = useEtherBlockCountdownV2(props.blockNumber.toString(), props.chainId); // TODO
    const countDownDate = Date.now() + countdown;
    return (<MissionLaunchTicker target={countDownDate} />);
}

function MissionLaunchTicker(props: { target: number; }) {
    const msLeft = Math.max(0, useCountDown(props.target));

    return (
        <div className="flex flex-row gap-4">
            <CountDownCol count={Math.floor(msLeft / 1000 / 60 / 60)} label="Hrs" />
            <CountDownCol count={Math.floor(msLeft / 1000 / 60) % 60} label="Min" />
            <CountDownCol count={Math.floor(msLeft / 1000) % 60} label="Sec" />
        </div>
    );
}
function CountDownCol(props: { count: number; label: string; }) {
    const formatted = props.count < 10 ? `0${props.count}` : props.count;
    return (
        <div className="flex flex-col items-center">
            <span className="text-4xl font-semibold">{formatted}</span>
            <span className="text-xs text-gray-500">{props.label}</span>
        </div>
    );
}
function MissionCountDownLoading() {
    return (
        <div className="flex flex-row gap-4">
            <LoadingCountDownCol label="Hrs" />
            <LoadingCountDownCol label="Min" />
            <LoadingCountDownCol label="Sec" />
        </div>
    );
}
function LoadingCountDownCol(props: { label: string; }) {
    return (
        <div className="flex flex-col items-center">
            <div className="flex flex-row gap-1">
                <div className="animate-pulse text-4xl bg-slate-700 rounded"><span className="opacity-0">0</span></div>
                <div className="animate-pulse text-4xl bg-slate-700 rounded"><span className="opacity-0">0</span></div>
            </div>

            <span className="text-xs text-gray-500">{props.label}</span>
        </div>
    );
}
