import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/Card";

export default function FeatureTeaserSection() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-stretch">
            <MissionProtocolTeaser />
            <MarketTeaser />
        </div>
    )
}

function MissionProtocolTeaser() {
    const navigate = useNavigate();
    return (
        <Card>
            <div className="flex flex-col w-full">
                <div className="text-xl font-semibold">The Mission Protocol</div>
                <div className="mt-4 text-lg">Gain up to</div>
                <div className="mt-1 font-bold text-3xl">1000 <span className="text-spex-500">SPEX</span></div>
                <div className="mt-4 text-lg">Explore the spex universe!</div>
                <div className="mt-1 text-sm text-gray-400 grow">Participating in daily missions allows you to claim huge rewards. Invest your SPEX now and grow your wealth!</div>
                <Button className="mt-6 justify-self-end align-self-end" onClick={(e) => navigate(`mission`)}>
                    <span>Join Now</span>
                </Button>
            </div>
        </Card>
    )
}

function MarketTeaser() {
    const navigate = useNavigate();
    return (
        <Card>
            <div className="flex flex-col w-full">
                <div className="text-xl font-semibold">Cosmic Markets</div>
                <div className="mt-4 text-lg">In need of new</div>
                <div className="mt-1 font-bold text-3xl"><span className="text-spex-500">SPEX</span> Tokens ?</div>
                <div className="mt-4 text-lg">We got you covered!</div>
                <div className="mt-1 text-sm text-gray-400 grow">Checkout the cosmic markets and fill up your wallet with fresh SPEX!</div>
                <Button className="mt-6" onClick={(e) => navigate(`market`)}>
                    <span>Buy Now</span>
                </Button>
            </div>
        </Card>
    )
}