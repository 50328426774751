import { Dialog, Transition } from "@headlessui/react";
import {
  createContext,
  DependencyList,
  Dispatch,
  Fragment,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/lotties/loading_alien.json";

export enum Web3Status {
  Approve = "approve",
  Pending = "wait",
  Generic = "generic",
}

type Web3LoadingStatus = Web3Status | string;

function getMessageFromStatus(s: Web3LoadingStatus) {
  return (
    {
      approve: "Approval necessary... Check your wallet!",
      generic: "Interaction required... Check your wallet!",
      wait: "Waiting for on chain confirmation...",
    }[s] ?? s
  );
}

type StatusSetter = (s: Web3LoadingStatus) => unknown;

const OverlayVisibleSetterContext = createContext<
  Dispatch<SetStateAction<Web3LoadingStatus | undefined>>
>(() => void 0);

export function useWeb3LoadingCallback<
  R,
  X,
  T extends (arg: X) => Promise<R>
>(
  callback: (setStatus: StatusSetter, arg: X) => Promise<R>,
  deps: DependencyList
): T {
  const setter = useContext(OverlayVisibleSetterContext);
  return useCallback(
    async function (arg: X): Promise<R> {
      setter(Web3Status.Generic);
      try {
        return await callback(setter, arg);
      } finally {
        setter(undefined);
      }
    } as T,
    [setter, ...deps]
  );
}

export default function Web3LoadingOverlay(props: PropsWithChildren<{}>) {
  const [visible, setVisible] = useState<string | undefined>(undefined);
  return (
    <OverlayVisibleSetterContext.Provider value={setVisible}>
      <>
        {props.children}
        <Transition appear show={visible !== undefined} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => void 0} // do nothing.
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75" />
              </Transition.Child>
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-xl border-2 border-gray-800 bg-gray-900 p-6 transition-all">
                  <div className="flex flex-col items-center">
                    <Lottie
                      animationData={loadingAnimation}
                      height={200}
                      width={200}
                    />
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-100"
                    >
                      {visible && getMessageFromStatus(visible)}
                    </Dialog.Title>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    </OverlayVisibleSetterContext.Provider>
  );
}
