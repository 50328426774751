import Button from "./Button"

export default function PercentageOptions(
    props: { total: number, onOptionSelected: (amount: number) => void }
) {
    return (
        <div className="flex flex-row space-x-2 place-content-center">
            <Button onClick={() => { props.onOptionSelected(props.total * 0.25) }}
                pill={true} size="small" variant="outlined" className="text-xs">25%</Button>
            <Button onClick={() => { props.onOptionSelected(props.total * 0.50) }}
                pill={true} size="small" variant="outlined" className="text-xs">50%</Button>
            <Button onClick={() => { props.onOptionSelected(props.total * 0.75) }}
                pill={true} size="small" variant="outlined" className="text-xs">75%</Button>
            <Button onClick={() => { props.onOptionSelected(props.total * 1) }}
                pill={true} size="small" variant="outlined" className="text-xs">Max</Button>
        </div>
    )
}