import ERC20ABI from "../contracts/ERC20.abi.json";
import { useContractView } from "../contracts/useCosmicContracts";
import { BigNumber } from "@ethersproject/bignumber";
import { useActiveWeb3React } from "./useActiveWeb3React";

export function useTokenBalances(address: string, owner?: string): BigNumber {
  const { account } = useActiveWeb3React();

  const d = useContractView({ address, abi: ERC20ABI }, "balanceOf", [
    owner ?? account,
  ]);
  return d ?? 0;
}

export function useTokenSymbol(address: string) {
  return useContractView({ address, abi: ERC20ABI }, "symbol", []);
}
