import NumericalInput from "./NumericalInput"

export default function TokenInput({
    header,
    value,
    footer,
    error,
    disabled,
    onUserInput,
    symbol,
    ...rest
}: {
    header?: string,
    value: string | number
    footer?: string,
    error?: string,
    disabled?: boolean
    onUserInput: (input: string) => void
    symbol: string
} & NonNullable<React.HTMLProps<HTMLDivElement>>) {
    return (
        <div {...rest}>
            <div className="flex flex-col">
                {header && <span className="text-xs text-gray-400">{header}</span>}
                <div className="flex flex-row rounded bg-gray-700 items-center py-1 mt-1">
                    <NumericalInput
                        disabled={disabled}
                        className="grow text-gray-400 bg-transparent pl-1.5"
                        value={value}
                        onUserInput={onUserInput}
                    />
                    <div className="text-sm text-grey-400 tracking-wide uppercase px-1.5 pl-2">{symbol}</div>
                </div>
                {footer && !error && <span className="place-self-end text-xs text-gray-500 mt-1">{footer}</span>}
                {error && <span className="place-self-end text-xs text-red-500 font-bold mt-1">{error}</span>}
            </div>
        </div>
    )
}