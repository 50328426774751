import useCosmicContracts, {
  useContract,
  useContractView,
  useERC20,
} from "../../contracts/useCosmicContracts";
import { BigNumber } from "@ethersproject/bignumber";
import { AddressZero } from "../../utils/constants";
import { useWeb3LoadingCallback, Web3Status } from "../../contracts/Web3LoadingOverlay";

export type Market = {
  capacity: BigNumber;
  quoteTokenAddress: string;
  priceUnscaled: BigNumber;
  priceScale: number;
  active: boolean;
  payoutToken: string;
};

export function useNumberOfMarkets() {
  const { SpexPreSaleMarket } = useCosmicContracts();
  const data: BigNumber = useContractView(SpexPreSaleMarket, "marketCount", []);
  return data?.toNumber() || 0;
}

export function useMarket(idx: number) {
  const { SpexPreSaleMarket } = useCosmicContracts();
  const market: Market = useContractView(SpexPreSaleMarket, "markets", [idx]);
  return market;
}

export function usePerformBuy(idx: number) {
  const { SpexPreSaleMarket } = useCosmicContracts();
  const marketInfo = useMarket(idx);

  const market = useContract(SpexPreSaleMarket);

  const depositToken = useERC20(marketInfo?.quoteTokenAddress ?? AddressZero);

  return useWeb3LoadingCallback(
    async (setStatus, amount: BigNumber) => {
      const options: any = {};
      if (marketInfo.quoteTokenAddress !== AddressZero) {
        setStatus(Web3Status.Approve);
        const txApprove = await depositToken.approve(
          SpexPreSaleMarket.address,
          amount
        );
        setStatus(Web3Status.Pending)
        await txApprove.wait();
      } else {
        options.value = amount;
      }
      setStatus(Web3Status.Generic)
      const txDeposit = await market.deposit(idx, amount, options);
      setStatus("Transfering fresh SPEX to your wallet...")
      await txDeposit.wait();
    },
    [marketInfo, market, depositToken, SpexPreSaleMarket, idx]
  );
}

export function calculateMarketPayout(
  value: BigNumber,
  market: Market
): BigNumber {
  const scale = BigNumber.from(10).pow(Math.abs(market.priceScale));

  const payoutUnscaled = value.mul(market.priceUnscaled);

  return market.priceScale > 0
    ? payoutUnscaled.div(scale)
    : payoutUnscaled.mul(scale);
}

export function calculateMarketPrice(
  desired: BigNumber,
  market: Market
): BigNumber {
  const scale = BigNumber.from(10).pow(Math.abs(market.priceScale));

  const inUnscaled = desired.div(market.priceUnscaled);

  return market.priceScale > 0 ? inUnscaled.mul(scale) : inUnscaled.div(scale);
}
