import badge01 from "./badge_01.png";
import badge02 from "./badge_02.png";
import badge03 from "./badge_03.png";
import badge04 from "./badge_04.png";
import badge05 from "./badge_05.png";
import badge06 from "./badge_06.png";
import badge07 from "./badge_07.png";
import badge08 from "./badge_08.png";
import badge09 from "./badge_09.png";
import badge10 from "./badge_10.png";
import badge11 from "./badge_11.png";
import badge12 from "./badge_12.png";
import badge13 from "./badge_13.png";
import badge14 from "./badge_14.png";
import badge15 from "./badge_15.png";

const all = {
  badge01,
  badge02,
  badge03,
  badge04,
  badge05,
  badge06,
  badge07,
  badge08,
  badge09,
  badge10,
  badge11,
  badge12,
  badge13,
  badge14,
  badge15,
};

export default all;
