import { formatEther } from "@ethersproject/units";
import { useState } from "react";
import { useCurrentBlockNumber } from "../../../hooks/useActiveWeb3React";
import { useEtherBlockCountdown } from "../../../hooks/useEtherscan";
import { useClaimMission, useStakedAmountToMission } from "../missions";
import { Mission } from "../../../hooks/useSubgraph";
import Button from "../../../components/Button";
import CountDownComponent from "../../../components/deprecated/CountDownLabel";
import { BigNumber } from "@ethersproject/bignumber";
import { prettyEther } from "../../../utils";

export default function ClaimPanel(props: { mission: Mission }) {
    function calculateClaim(stake: BigNumber | undefined, totalReward: BigNumber, totalStake: BigNumber, tax: number) {
        if (!stake || stake.lte(0)) {
            return BigNumber.from(0)
        }
        const reward = totalReward.mul(stake).div(totalStake.isZero() ? stake : totalStake)
        const taxAmount = reward.mul(tax).div(100)
        return reward.sub(taxAmount)
    }
    const mission = props.mission;
    const [pendingClaim, setPendingClaim] = useState(false)
    const claimMission = useClaimMission();
    const currentBlockNumber = useCurrentBlockNumber();
    const stake = useStakedAmountToMission(mission.id);

    const investors = 400; // TODO
    const tax = mission.tax
    const estClaim = calculateClaim(stake, mission.totalReward, mission.totalStake, tax)

    const missionLocked = mission.start.toNumber() < currentBlockNumber && currentBlockNumber < mission.end.toNumber();

    const onClaim = async () => {
        try {
            setPendingClaim(true)
            await claimMission(mission.id);
        } finally {
            setPendingClaim(false)
        }
    }

    const bttnText = pendingClaim ? "Waiting for confirmation" : "Claim"
    const claimDisabled = missionLocked || estClaim.lte(0) || pendingClaim;
    return (
        <div className="flex flex-col w-full">
            <span className="text-xs text-gray-400">Your Position</span>
            <span className="text-xl font-bold mt-1.5">{formatEther(stake || 0)} <span className="text-spex-500">SPEX</span> STAKED</span>

            <div className="grid grid-cols-2 mt-2 gap-0.5">
                <span className="text-sm text-gray-300">Total Investors</span>
                <span className="text-sm text-gray-300 place-self-end">{investors}</span>
                <span className="text-sm text-gray-300">Guild Tax</span>
                <span className="text-sm text-gray-300 place-self-end">{tax}%</span>
                <span className="text-sm text-gray-300">Estimated Yield</span>
                <span className="text-sm text-gray-300 place-self-end">{prettyEther(estClaim, 1)} SPEX</span>
            </div>

            <BlockCountDownComponent start={mission.start.toNumber()} end={mission.end.toNumber()}></BlockCountDownComponent>
            <Button onClick={() => { onClaim() }} className="mt-4 w-full" disabled={claimDisabled}>{bttnText}</Button>
        </div>
    )
}

function BlockCountDownComponent(props: { start: number, end: number }) {
    const currentBlockNumber = useCurrentBlockNumber();
    const missionEnded = currentBlockNumber > props.end;

    if (missionEnded) {
        return (
            <span className="place-self-end font-bold text-xs mt-2 text-red-400">Mission Finished</span>
        )
    }

    const missionLaunched = props.start < currentBlockNumber && currentBlockNumber < props.end;

    const block = missionLaunched ? props.end : props.start;
    return (
        <MissionCountDownComponent blockNumber={block} hasLaunched={missionLaunched} />
    )
}

function MissionCountDownComponent(props: { blockNumber: number, hasLaunched: boolean }) {
    const { countDown, isError } = useEtherBlockCountdown(props.blockNumber.toString())

    if (countDown) {
        const countDownDate = Date.now() + countDown;
        return (
            <>
                {
                    !props.hasLaunched &&
                    (<span className="place-self-end font-bold text-xs mt-2">Launch in <CountDownComponent target={(countDownDate)} /></span>)
                }
                {
                    props.hasLaunched &&
                    (<span className="place-self-end font-bold text-xs mt-2">Returns in <CountDownComponent target={(countDownDate)} /></span>)
                }
            </>
        )
    }

    if (isError) {
        return (
            <span className="place-self-end font-bold text-xs mt-2 text-red-500">Failed to retrieve lauch status...</span>
        )
    }

    return (
        <span className="place-self-end font-bold text-xs mt-2">Retrieving lauch status...</span>
    )
}

