import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
  ApolloQueryResult,
} from "@apollo/client";
import { useRef, useState } from "react";
import { useSyncExternalStore } from "@apollo/client/react/hooks/useSyncExternalStore";

function deepEqual(objA: any, objB: any, map = new WeakMap()): boolean {
  // P1
  if (Object.is(objA, objB)) return true;

  // P2
  if (objA instanceof Date && objB instanceof Date) {
    return objA.getTime() === objB.getTime();
  }
  if (objA instanceof RegExp && objB instanceof RegExp) {
    return objA.toString() === objB.toString();
  }

  // P3
  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  // P4
  if (map.get(objA) === objB) return true;
  map.set(objA, objB);

  // P5
  const keysA = Reflect.ownKeys(objA);
  const keysB = Reflect.ownKeys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Reflect.has(objB, keysA[i]) ||
      !deepEqual(objA[keysA[i]], objB[keysA[i]], map)
    ) {
      return false;
    }
  }

  return true;
}

export function useSuspendedQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  props?: QueryHookOptions<TData, TVariables>
): ApolloQueryResult<TData> {
  const client = useApolloClient();
  const snapShotCache = useRef<ApolloQueryResult<TData>>();

  const [observedQuery] = useState(() => {
    return client.watchQuery<TData, TVariables>({ query, ...props });
  });

  const data = useSyncExternalStore(
    (store: Function) => {
      const unSub = observedQuery.subscribe(() => {
        store();
      });
      return () => {
        unSub.unsubscribe();
      };
    },
    () => {
      const result = observedQuery.getCurrentResult();
      const isEqual = deepEqual(snapShotCache.current, result);

      const newValue = (
        isEqual ? snapShotCache.current : result
      ) as ApolloQueryResult<TData>;

      if (!isEqual) {
        snapShotCache.current = newValue;
      }
      return newValue;
    }
  );

  const cache = client.readQuery<TData, TVariables>({ query, ...props });

  if (!cache) {
    const { fetchPolicy, ...newProps } = props ?? {};
    const policy = client.defaultOptions.query?.fetchPolicy;

    throw client.query({
      query,
      ...newProps,
      fetchPolicy: policy,
    });
  }
  return data;
}
