import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { fetchEtherBlockCountdown, fetchLatestBlockNumber, resolveEtherscanBaseUrl } from "../services/etherscan";

export function useActiveWeb3React() {
  return useWeb3React();
}

export function useIsLoggedIn() {
  const w3 = useActiveWeb3React();

  return !!w3.account;
}

export function useChainId() {
  return useActiveWeb3React().chainId || 5;
}

export function useCurrentBlockNumber(): number {
  const { library } = useActiveWeb3React();
  const { data } = useSWR(
    "getBlockNumber",
    async (m: string) => {
      if (!library) {
        return -1;
      }
      return library.getBlockNumber();
    },
    { suspense: true }
  );
  return data;
}

export function useLatestBlockNumberV2(chainId?: number): number {
  const { library } = useActiveWeb3React();
  const currChain = useChainId()

  const { data } = useSWR(
    "getLatestBlockNumber",
    async () => {
      if (library) {
        return library.getBlockNumber();
      }
      return fetchLatestBlockNumber(resolveEtherscanBaseUrl(chainId || currChain));
    }, { suspense: true });
  return data;
}

export function useBlockTimestamp(blockNumber: number, chain?: number) {
  const { library } = useActiveWeb3React();
  const currChain = useChainId()
  const requestChainId = chain || currChain;
  const currentBlockNumber = useLatestBlockNumberV2();
  const { data } = useSWR(
    [blockNumber, requestChainId],
    async () => {
      if (blockNumber < currentBlockNumber) {
        const time = await library.getBlock(blockNumber);
        return new Date(time.timestamp * 1000);
      } else {
        const time = await fetchEtherBlockCountdown(blockNumber.toString(), resolveEtherscanBaseUrl(requestChainId));
        return new Date(Date.now() + time);
      }
    }, { suspense: true });
  return data!!; // TODO why undefined???
}