import ConnectButton from "./ConnectButton";
import { NavLink } from "react-router-dom";
import logo from "./logo.png";
import { Suspense } from "react";

export default function Header() {
  return (
    <nav className="flex h-20 flex-row items-center justify-between py-4">
      <img src={logo} alt="SPEX logo" className="h-6" />
      <div className="pointer-events-none absolute inset-0 left-0 right-0 flex items-end justify-center py-4 md:items-start">
        <div className="pointer-events-auto flex flex-row space-x-4 rounded-full bg-gray-900 px-2 py-2">
          <NavLink
            to="/"
            className={({ isActive }) =>
              "block rounded-full px-6 py-1 " + (isActive ? "bg-gray-700" : "")
            }
            title="wen moon"
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/mission"
            className={({ isActive }) =>
              "block rounded-full px-6 py-1 " + (isActive ? "bg-gray-700" : "")
            }
            title="earn MOOORE SPEX"
          >
            Missions
          </NavLink>
          <NavLink
            to="/market"
            className={({ isActive }) =>
              "block rounded-full px-6 py-1 " + (isActive ? "bg-gray-700" : "")
            }
            title="buy SPEX"
          >
            Markets
          </NavLink>
        </div>
      </div>
      <Suspense fallback={<>Loading...</>}>
        <ConnectButton />
      </Suspense>
    </nav>
  );
}
