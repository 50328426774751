import { hasMissionEnded, hasMissionLaunched } from "../missions";
import { Mission } from "../../../hooks/useSubgraph";
import { useLatestBlockNumberV2 } from "../../../hooks/useActiveWeb3React";
import cx from "classnames";
import ErrorBoundary from "../../../ErrorBoundary";
import { Suspense } from "react";

type Props = {
    mission: Mission;
    chainId: number
};

function MissionStateLabel(props: { state: string; className?: string; }) {
    return (
        <div className={cx("rounded text-gray-100 border-none bg-spex-500 p-1 uppercase", props.className)}>{props.state}</div>
    );
}
function MissionStateLoading() {
    return (
        <MissionStateLabel state="Loading" className="bg-gray-700 animate-pulse" />
    );
}

function MissionStateInternal(props: Props) {
    function getMissionState(mission: Mission, currentBlock: number) {
        if (hasMissionEnded(mission, currentBlock)) {
            return "Success";
        } else if (hasMissionLaunched(mission, currentBlock)) {
            return "Active";
        }
        return "Upcoming";
    }

    const currentBlock = useLatestBlockNumberV2(props.chainId);
    return (
        <MissionStateLabel state={getMissionState(props.mission, currentBlock)} />
    );
}
export function MissionState(props: Props) {
    return (
        <div>
            <ErrorBoundary render={() => <MissionStateLabel state="NaN" />}>
                <Suspense fallback={<MissionStateLoading />}>
                    <MissionStateInternal mission={props.mission} chainId={props.chainId} />
                </Suspense>
            </ErrorBoundary>
        </div>
    );
}
