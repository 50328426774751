import { PropsWithChildren, Suspense } from "react";
import ErrorBoundary from "../../ErrorBoundary";
import { useClaims, useInvests, useMissions } from "../../hooks/useSubgraph";
import Card from "../../components/Card";
import { BigNumber } from "@ethersproject/bignumber";
import { prettyEther } from "../../utils";

export default function SpexStatsSection() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-stretch">
            <SpexLockedCard />
            <SpexClaimedCard />
            <MissionLaunchedCard />
        </div>
    )
}

function SpexLockedCard() {
    return (
        <SpexInfoCard>
            <SpexLockedInfo />
        </SpexInfoCard>
    )
}

function SpexClaimedCard() {
    return (
        <SpexInfoCard>
            <SpexClaimedInfo />
        </SpexInfoCard>
    )
}

function MissionLaunchedCard() {
    return (
        <SpexInfoCard>
            <MissionLaunchedInfo />
        </SpexInfoCard>
    )
}

function SpexInfoCard(props: PropsWithChildren<any>) {
    return (
        <div>
            <ErrorBoundary>
                <Card className="flex place-content-center">
                    <Suspense fallback={<InfoLoadingSkeleton />}>
                        {props.children}
                    </Suspense>
                </Card>
            </ErrorBoundary >
        </div>
    )
}

function SpexClaimedInfo() {
    const claims = useClaims();
    const claimed = claims.reduce((acc, claim) => acc.add(claim.claim), BigNumber.from(0));
    return (
        <SpexInfo value={prettyEther(claimed, 1)} highlight="SPEX" subtitle="Rewards Claimed" />
    )
}

function SpexLockedInfo() {
    const investments = useInvests()
    const investment = investments.reduce((acc, invest) => acc.add(invest.invest), BigNumber.from(0))
    return (
        <SpexInfo value={prettyEther(investment, 0)} highlight="SPEX" subtitle="Locked In Missions" />
    )
}

function MissionLaunchedInfo() {
    const missions = useMissions()
    const missionLaunched = missions.length
    return (
        <SpexInfo value={missionLaunched.toString()} highlight="Missions" subtitle="Already Launched" />
    )
}

function SpexInfo(props: { value: string, highlight: string, subtitle: string }) {
    return (
        <div className="flex flex-col justify-center items-center space-y-2">
            <span className="font-bold text-3xl">{props.value} <span className="text-spex-500">{props.highlight}</span></span>
            <span className="text-gray-400">{props.subtitle}</span>
        </div>
    )
}

function InfoLoadingSkeleton() {
    return (
        <div className="flex flex-col animate-pulse items-center space-y-2">
            <div className="h-7 w-48 bg-slate-700 rounded"></div>
            <div className="h-5 w-32 bg-slate-700 rounded"></div>
        </div>
    )
}

