import Header from "./Header";
import { Route, Routes } from "react-router-dom";
import MarketPage from "./pages/market/MarketPages";
import MissionOverviewPage from "./pages/mission/MissionOverviewPage";
import { useLatestBlockNumberV2 } from "./hooks/useActiveWeb3React";
import { Suspense } from "react";
import SpexStatsSection from "./pages/dashboard/SpexStatsSection";
import FeatureTeaserSection from "./pages/dashboard/MissionProtoTeaser";
import SocialsSection from "./pages/dashboard/SocialSection";
import MissionPage from "./pages/mission/MissionPage";

function Index() {
  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col w-3/4 self-center space-y-8">

        <SpexStatsSection />
        <FeatureTeaserSection />
        <SocialsSection />
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <div className="absolute bg-header w-full h-[468px] bg-cover saturate-50" />
      <div className="relative container mx-auto max-w-7xl px-2 md:px-8 pb-8 ">
        <Header />
        <div className="mt-24">
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              <Route index element={<Index />} />
              <Route path="mission" element={<MissionOverviewPage />} />
              <Route path="market" element={<MarketPage />} />
              <Route path="mission/:id" element={<MissionPage />} />
            </Routes>
          </Suspense>
        </div>
        <Suspense fallback={<></>}>
          <BlockNumberDisplay />
        </Suspense>
      </div>
    </>
  );
}

export default App;

function BlockNumberDisplay() {
  const currentBlockNumber = useLatestBlockNumberV2();
  return (
    <div className="absolute bottom-0 right-0 p-2">
      <div className="rounded px-4 py-1 text-gray-800 hover:bg-gray-400">
        {currentBlockNumber}
      </div>
    </div>
  );
}
