import useCosmicContracts, {
  useContract,
  useContractView,
} from "../../contracts/useCosmicContracts";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { useActiveWeb3React } from "../../hooks/useActiveWeb3React";
import badges from "../../assets/badges";
import { formatEther } from "@ethersproject/units";
import { useWeb3LoadingCallback, Web3Status } from "../../contracts/Web3LoadingOverlay";
import { Mission } from "../../hooks/useSubgraph";

export function useJoinMission(mid: BigNumberish) {
  const { MissionV1, SPEXToken } = useCosmicContracts();
  const spex = useContract(SPEXToken);
  const missionV1 = useContract(MissionV1);

  return useWeb3LoadingCallback(
    async (setStatus, amount: BigNumberish) => {
      setStatus(Web3Status.Approve);
      const txSign = await spex.approve(MissionV1.address, amount);
      setStatus(Web3Status.Pending);
      await txSign.wait(); // We need to wait for the approval to be mined
      setStatus(Web3Status.Generic);
      const txJoin = await missionV1.join(mid, amount);
      setStatus(Web3Status.Pending);
      await txJoin.wait();
    },
    [MissionV1, mid, spex, missionV1]
  );
}

export function useClaimMission() {
  const { account } = useActiveWeb3React();
  const { MissionV1 } = useCosmicContracts();
  const missionV1 = useContract(MissionV1);

  return useWeb3LoadingCallback(
    async (setStatus, mid: BigNumberish) => {
      // 0 == msg.sender
      setStatus(Web3Status.Generic);
      const txClaim = await missionV1.claim(mid, account);
      setStatus(Web3Status.Pending);
      await txClaim.wait();
    },
    [missionV1, account]
  );
}

export function useStakedAmountToMission(
  mid: BigNumberish
): BigNumber | undefined {
  const { account } = useActiveWeb3React();
  const { MissionV1 } = useCosmicContracts();
  const data = useContractView(MissionV1, "userInfo", [mid, account]);
  return data as BigNumber | undefined;
}

export function useContractMission(mid: BigNumberish) {
  const { MissionV1 } = useCosmicContracts();
  const data = useContractView(MissionV1, "missions", [mid]);
  console.log(JSON.stringify(data));
  return data as Array<any> | undefined;
}

export function useImageSrcForMission(mid: BigNumber): string {
  if (mid === undefined) {
    return badges.badge01;
  }

  const badgesLength = Object.keys(badges).length;
  const type = Math.min(mid.mod(100).toNumber(), badgesLength - 1);

  if (type >= Object.keys(badges).length) {
    throw new Error(`unsupported mission type '${type}'`);
  }

  return badges[Object.keys(badges)[type] as keyof typeof badges];
}

export function useAPR(mission: Mission): number {
  if (mission.totalStake.isZero()) {
    return Infinity;
  }
  const interest = parseFloat(formatEther(mission.totalReward));
  const principle = parseFloat(formatEther(mission.totalStake));
  const n = mission.end.sub(mission.start).toNumber();
  const blocksPerYear = 43200 * 356;

  return (interest / principle / n) * blocksPerYear;
}

export function hasMissionLaunched(mission: Mission, currentBlock: number) {
  return mission.start.lte(currentBlock);
}

export function hasMissionEnded(mission: Mission, currentBlock: number) {
  return mission.end.lt(currentBlock);
}

export function isMissionActive(mission: Mission, currentBlock: number) {
  return (
    hasMissionLaunched(mission, currentBlock) &&
    !hasMissionEnded(mission, currentBlock)
  );
}
