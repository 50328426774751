import { PropsWithChildren, SyntheticEvent } from "react";
import Button from "../../components/Button"
import Card from "../../components/Card";

export default function SocialsSection() {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-stretch">
            <GitbookLinkCard />
            <SocialLinksCard />
        </div>
    )
}

function GitbookLinkCard() {
    const gitbookUrl = "https://spex.gitbook.io/spex/";
    return (
        <Card>
            <div className="flex flex-col w-full place-items-center mx-8">
                <div className="font-bold text-3xl mx-4">How does it work?</div>
                <Button className="mt-8 self-stretch" onClick={(e) => window.open(gitbookUrl)}>
                    Learn More &gt;
                </Button>
            </div>
        </Card>
    )
}

function SocialLinksCard() {
    const gitbookUrl = "https://spex.gitbook.io/spex/";
    return (
        <Card>
            <div className="flex flex-col w-full place-items-center mx-8">
                <div className="font-bold text-3xl mx-4">Connect with us</div>
                <div className="flex flex-row mt-8 gap-4 h-12 place-items-center">
                    <TwitterButton />
                    <TelegramButton />
                    <DiscordButton />
                </div>
            </div>
        </Card>
    )
}

function IconButton(props: PropsWithChildren<{ onClick: undefined | ((e: SyntheticEvent) => void); }>) {
    return (
        <button onClick={props.onClick}>
            <svg className="fill-white hover:fill-gray-400" viewBox="0 0 20 20" width="35" height="35">
                {props.children}
            </svg>
        </button>
    )
}

function DiscordButton() {
    const discordLink = "https://discord.gg/NwNZCkkR"
    return (
        <IconButton onClick={(e) => { window.open(discordLink) }}>
            <path d="M16.93 1.644A16.491 16.491 0 0012.86.38a.062.062 0 00-.066.031c-.175.313-.37.72-.506 1.041a15.226 15.226 0 00-4.573 0A10.54 10.54 0 007.2.412a.064.064 0 00-.065-.031 16.447 16.447 0 00-4.07 1.263.058.058 0 00-.028.023C.444 5.54-.266 9.319.083 13.05a.069.069 0 00.026.047 16.584 16.584 0 004.994 2.525.064.064 0 00.07-.023c.385-.526.728-1.08 1.022-1.662a.063.063 0 00-.035-.088 10.917 10.917 0 01-1.56-.744.064.064 0 01-.007-.106c.105-.079.21-.16.31-.243a.062.062 0 01.065-.009c3.273 1.495 6.817 1.495 10.051 0a.062.062 0 01.066.008c.1.083.204.165.31.244a.064.064 0 01-.005.106c-.499.291-1.017.537-1.561.743a.064.064 0 00-.034.089c.3.582.643 1.135 1.02 1.66a.063.063 0 00.07.025 16.53 16.53 0 005.003-2.525.064.064 0 00.026-.046c.417-4.314-.699-8.061-2.957-11.384a.05.05 0 00-.026-.023zM6.684 10.778c-.985 0-1.797-.905-1.797-2.016 0-1.11.796-2.015 1.797-2.015 1.01 0 1.814.912 1.798 2.015 0 1.111-.796 2.016-1.798 2.016zm6.646 0c-.986 0-1.797-.905-1.797-2.016 0-1.11.796-2.015 1.797-2.015 1.009 0 1.813.912 1.797 2.015 0 1.111-.788 2.016-1.797 2.016z"></path>
        </IconButton>
    )
}

function TwitterButton() {
    const twitterLink = "https://twitter.com/spex-official"
    return (
        <IconButton onClick={(e) => { }}>
            <path d="M5.659 15c6.79 0 10.507-5.766 10.507-10.763 0-.16 0-.32-.01-.49A7.578 7.578 0 0018 1.79c-.663.3-1.376.5-2.127.6a3.824 3.824 0 001.63-2.1c-.713.44-1.503.75-2.352.92A3.6 3.6 0 0012.46 0C10.419 0 8.76 1.699 8.76 3.787c0 .3.039.58.098.86-3.064-.15-5.786-1.669-7.61-3.957A3.858 3.858 0 00.75 2.598c0 1.31.654 2.469 1.64 3.148a3.638 3.638 0 01-1.669-.47v.05c0 1.83 1.278 3.368 2.956 3.708-.312.09-.634.13-.976.13-.234 0-.468-.02-.692-.07.468 1.509 1.834 2.598 3.453 2.628a7.284 7.284 0 01-4.585 1.62c-.293 0-.595-.01-.878-.05A10.206 10.206 0 005.659 15z"></path>
        </IconButton>
    )
}

function TelegramButton() {
    const telegramLink = "https://t.me/spexofficial"
    return (
        <IconButton onClick={(e) => { }}>
            <path d="M10 0C4.478 0 0 4.478 0 9.99 0 15.511 4.478 20 10 20s10-4.488 10-10.01C20 4.477 15.522 0 10 0zm4.925 6.28c-.064.927-1.78 7.856-1.78 7.856s-.107.406-.48.416a.644.644 0 01-.49-.192c-.395-.33-1.29-.97-2.132-1.556a.953.953 0 01-.107.096c-.192.17-.48.416-.789.714a10.7 10.7 0 00-.373.352l-.01.01a2.214 2.214 0 01-.193.171c-.415.341-.458.053-.458-.096l.224-2.441v-.021l.01-.022c.011-.032.033-.043.033-.043s4.36-3.88 4.477-4.296c.01-.021-.021-.042-.074-.021-.288.096-5.31 3.273-5.864 3.625-.032.02-.128.01-.128.01l-2.441-.8s-.288-.117-.192-.383c.021-.053.053-.107.17-.181.544-.384 10-3.785 10-3.785s.267-.085.427-.032c.074.032.117.064.16.17.01.043.021.128.021.224 0 .054-.01.118-.01.224z"></path>
        </IconButton>
    )
}