import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import "./tailwind.generated.css";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/800.css";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import App from "./App";
import getLibrary from "./utils/getLibrary";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { THE_GRAPH_ENDPOINT } from "./utils/constants";
import Web3LoadingOverlay from "./contracts/Web3LoadingOverlay";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");

const client = new ApolloClient({
  uri: THE_GRAPH_ENDPOINT,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<>Loading...</>}>
      <Web3LoadingOverlay>
        <ApolloProvider client={client}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </ApolloProvider>
      </Web3LoadingOverlay>
    </Suspense>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
