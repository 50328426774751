import { ReactNode, SyntheticEvent } from "react";
import { cls } from "../utils";

const classes = {
  base: "rounded-md py-2 px-4 font-semibold text-white shadow-md focus:outline-none focus:ring-2 focus:ring-spex-400 focus:ring-opacity-75",
  disabled: 'opacity-50 cursor-not-allowed',
  pill: 'rounded-full',
  size: {
    small: 'px-2 py-1 text-xs',
    normal: 'px-4 py-2',
    large: 'px-8 py-3 text-lg'
  },
  variant: {
    primary: 'bg-spex-500 hover:bg-spex-700 focus:ring-spex-400',
    outlined: 'bg-transparent border border-grey-200 text-grey-200 hover:border-spex-500 focus:border-spex-500 focus:text-spex-500',
  }
}

type Props = {
  children: ReactNode,
  className?: string,
  variant?: string,
  size?: string,
  pill?: boolean,
  disabled?: boolean,
  onClick: undefined | ((e: SyntheticEvent) => void);
};

export default function Button(props: Props) {
  const { onClick } = props;
  const variant = props.variant || 'primary';
  const size = props.size || 'normal';
  const pill = props.pill || false;
  const disabled = props.disabled || false;

  return (
    <button
      className={(cls(`
      ${classes.base} 
      ${classes.variant[variant as keyof typeof classes.variant]}]}]} 
      ${classes.size[size as keyof typeof classes.size]}]}]}]}]} 
      ${pill && classes.pill}
      ${disabled && classes.disabled}
      ${props.className}`))}
      disabled={disabled}
      onClick={onClick}>{props.children}</button>
  )
}
