import { formatEther } from "@ethersproject/units";
import Card from "../../../components/Card";
import { Mission } from "../../../hooks/useSubgraph";
import { MissionState } from "./MissionState";
import { useImageSrcForMission } from "../missions";
import { useNavigate } from "react-router-dom";

export default function MissionTeaserCard(props: { mission: Mission, chainId: number, moodImageUrl?: string }) {
    const navigate = useNavigate()
    const mission = props.mission
    const badgeSrc = useImageSrcForMission(mission.id);
    const onCardClicked = () => { navigate("/mission/" + props.mission.id) }
    return (
        <div>
            <Card className="p-0 max-w-sm relative overflow-hidden" onClick={onCardClicked}>
                <div className="p-3 flex flex-col w-full ">
                    <div className="flex flex-col relative items-end h-48">
                        <img className="absolute bg-gray-800 w-full h-full rounded-lg object-cover overflow-visible border border-gray-900" src={props.moodImageUrl} />
                        <div className="z-10 m-2">
                            <MissionState mission={mission} chainId={props.chainId} />
                        </div>
                    </div>
                    <div className="flex flex-row py-2 items-center">
                        <img src={badgeSrc} alt="Mission Badge" className="h-12 w-12" />
                        <div className="flex flex-col grow items-end">
                            <div className="text-2xl font-semibold text-gray-200">Mission: 0x{mission.id.toString()}</div>
                            <span className="text-sm text-gray-500">Est. Reward: {formatEther(props.mission.totalReward)} SPEX</span>
                        </div>
                    </div>
                </div>
                <div className="absolute bg-black w-full h-full opacity-0 transition-opacity duration-500 hover:opacity-20" />
            </Card>
        </div>)
}