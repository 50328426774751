import { BigNumber } from "@ethersproject/bignumber";
import { useSuspendedQuery } from "./useSuspendedQuery";
import { gql } from "@apollo/client";

export type Mission = {
  id: BigNumber;
  missionId: string;
  start: BigNumber;
  end: BigNumber;
  totalReward: BigNumber;
  totalStake: BigNumber;
  pendingStake: BigNumber;
  tax: number;
};

export type Invest = {
  id: BigNumber;
  address: string;
  invest: BigNumber;
  mission: Mission;
};

export type Claim = {
  id: BigNumber;
  address: string;
  claim: BigNumber;
  mission: Mission;
};

export function useMissions() {
  const { data } = useSuspendedQuery(gql`
    {
      missions {
        id
        missionId
        start
        end
        reward
        total
        pending
        tax
      }
    }
  `);
  return (data.missions as Array<any>).map<Mission>((entity) => ({
    id: BigNumber.from(entity.id),
    missionId: entity.missionId,
    start: BigNumber.from(entity.start),
    end: BigNumber.from(entity.end),
    totalReward: BigNumber.from(entity.reward),
    totalStake: BigNumber.from(entity.total),
    pendingStake: BigNumber.from(entity.pending),
    tax: entity.tax,
  }));
}

export function useClaims() {
  const { data } = useSuspendedQuery(gql`
  {
    claims {
      id
      address
      claim
      mission {
        id
        missionId
        start
        end
        reward
        total
        pending
        tax
      }
    }
  }
  `);
  return (data.claims as Array<any>).map<Claim>((entity) => ({
    id: entity.id,
    address: entity.address,
    claim: BigNumber.from(entity.claim),
    mission: {
      id: BigNumber.from(entity.mission.id),
      missionId: entity.mission.missionId,
      start: BigNumber.from(entity.mission.start),
      end: BigNumber.from(entity.mission.end),
      totalReward: BigNumber.from(entity.mission.reward),
      totalStake: BigNumber.from(entity.mission.total),
      pendingStake: BigNumber.from(entity.mission.pending),
      tax: entity.mission.tax,
    }
  }));
}

export function useInvests() {
  const { data } = useSuspendedQuery(gql`
  {
    invests {
      id
      address
      invest
      mission {
        id
        missionId
        start
        end
        reward
        total
        pending
        tax
      }
    }
  }
  `);
  return (data.invests as Array<any>).map<Invest>((entity) => ({
    id: BigNumber.from(entity.id),
    address: entity.address,
    invest: BigNumber.from(entity.invest),
    mission: {
      id: BigNumber.from(entity.mission.id),
      missionId: entity.mission.missionId,
      start: BigNumber.from(entity.mission.start),
      end: BigNumber.from(entity.mission.end),
      totalReward: BigNumber.from(entity.mission.reward),
      totalStake: BigNumber.from(entity.mission.total),
      pendingStake: BigNumber.from(entity.mission.pending),
      tax: entity.mission.tax,
    }
  }),
  );
}

