const EtherscanMain = "https://api.etherscan.io/api";
const EtherscanGoerli = "https://api-goerli.etherscan.io/api";

export async function fetchEtherBlockCountdown(blockno: string, baseurl = EtherscanMain): Promise<number> {
    const response = await fetch(baseurl +
        "?module=block" +
        "&action=getblockcountdown" +
        "&blockno=" + blockno +
        "&apikey=" + "Q1Z3YCYGY3DPD2S5XRRVMUPANVC2HZB5NR"
    )
    if (!response.ok) {
        throw new Error("HTTP error " + response.status);
    }
    const data = await response.json();
    if (data.status != 1) {
        throw new Error("Etherscan API error " + JSON.stringify(data));
    }
    return (data.result.EstimateTimeInSec * 1000) as number // convert to milliseconds
}

export async function fetchLatestBlockNumber(baseurl = EtherscanMain) {
    const tsInSec = Math.floor(Date.now() / 1000);
    const response = await fetch(baseurl +
        "?module=block" +
        "&action=getblocknobytime" +
        "&timestamp=" + tsInSec +
        "&closest=before" +
        "&apikey=" + "Q1Z3YCYGY3DPD2S5XRRVMUPANVC2HZB5NR"
    );
    if (!response.ok) {
        throw new Error("HTTP error " + response.status);
    }
    const data = await response.json();
    if (data.status != 1) {
        throw new Error("Etherscan API error " + JSON.stringify(data));
    }
    return data.result as number;
}

export function resolveEtherscanBaseUrl(chainId?: number) {
    switch (chainId) {
        case 1:
            return EtherscanMain;
        case 5:
            return EtherscanGoerli;
        default:
            return undefined;
    }
}