import { PropsWithChildren, SyntheticEvent } from "react";
import cx from "classnames";

type Props = {
    className?: string;
    onClick?: undefined | ((e: SyntheticEvent) => void);
};

export default function Card(props: PropsWithChildren<Props>) {
    return (
        <div className={cx("flex bg-gray-900 rounded-2xl p-6 shadow-2xl border border-gray-800", props.className)}
            onClick={props.onClick}>
            {props.children}
        </div>
    );
}