import { useParams } from "react-router-dom"
import { useActiveWeb3React, useChainId, useLatestBlockNumberV2 } from "../../hooks/useActiveWeb3React"
import { Mission, useMissions } from "../../hooks/useSubgraph"
import Card from "../../components/Card"
import MissionCountDown from "./components/MissionCountDown"
import MissionBriefingStats from "./components/MissionBriefingStats"
import { StakingPanel } from "./components/StakingPanel"
import ClaimPanel from "./components/ClaimPanel"
import SocialsSection from "../dashboard/SocialSection"
import ErrorBoundary from "../../ErrorBoundary"
import { Suspense } from "react"
import Button from "../../components/Button"
import { useInjectedConnector } from "../../ConnectButton"
import { prettyEther } from "../../utils"

export default function MissionPage() {
    const { id } = useParams()
    const missions = useMissions()
    const mission = missions.find(m => m.id.toString() === id)
    if (!mission) {
        return (
            <Card>
                <div className="flex text-lg items-center justify-center w-full h-32 text-red-400 font-semibold">Error: Mission {id} does not exist!</div>
            </Card>
        )
    }
    return (
        <div className="flex flex-col gap-12">
            <RewardCard mission={mission}></RewardCard>
            <ErrorBoundary render={(err) => <ConnectWalletTeaser />}>
                <Suspense fallback={<></>}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Card><StakingPanel mission={mission}></StakingPanel></Card>
                        <Card><ClaimPanel mission={mission}></ClaimPanel></Card>
                    </div>
                </Suspense>
            </ErrorBoundary>

            <MissionBriefingStats mission={mission}></MissionBriefingStats>
            <SocialsSection />
        </div>
    )
}

function ConnectWalletTeaser() {
    const injected = useInjectedConnector();
    const { activate } = useActiveWeb3React();

    const startConnect = async () => {
        await activate(injected);
    };
    return (
        <Card>
            <div className="flex flex-col items-center w-full px-8">
                <div className="text-3xl font-semibold text-spex-400 uppercase text-center ">Are you connected?</div>
                <div className="flex text-gray-400 mt-4 text-center">It seems like you have not connected your wallet yet! Without a connection you
                    will not be able to interact with any mission. </div>
                <div className="flex text-lg font-semibold text-gray-200 mt-6 text-center"> Time to act before these sweet rewards are claimed by the competition!</div>
                <Button className="mt-6 w-full md:w-2/5 max-w-96" onClick={startConnect}>Connect Me Now!</Button>
            </div>
        </Card >
    )
}

function RewardCard(props: { mission: Mission }) {
    const chainId = useChainId();
    const currentBlockNumber = useLatestBlockNumberV2();
    const missionLaunched = props.mission.start.lt(currentBlockNumber) && props.mission.end.gt(currentBlockNumber)
    const block = missionLaunched ? props.mission.end : props.mission.start;
    const missionOver = block.lt(currentBlockNumber)
    const launchTitle = missionLaunched ? "Mission Return In" : "Mission Launch In"

    return (
        <div>
            <Card>
                <div className="flex flex-col items-center w-full">
                    <div className="text-lg text-gray-500">Total Rewards Of</div>
                    <div className="text-5xl font-bold mt-1">{prettyEther(props.mission.totalReward, 0)} <span className="text-spex-500">SPEX</span></div>
                    {
                        missionOver &&
                        <MissionEndedStatus />
                    }
                    {
                        !missionOver &&
                        <>
                            <div className="text-lg text-gray-400 mt-5">{launchTitle}</div>
                            <div className="mt-3 mb-5">
                                <MissionCountDown blockNumber={block} chainId={chainId} />
                            </div>
                        </>
                    }
                </div>
            </Card>
        </div>
    )
}

function MissionEndedStatus() {
    return (
        <span className="font-bold text-3xl text-red-500 mt-7 mb-7">Mission Finished</span>
    )
}